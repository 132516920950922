///////////////////////////////////////////////////
// SPOTIFY ALBUMS
.spotify-album-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: 1rem;
  }
  
  .spotify-album-grid .custom-album-check input:hover {
    cursor: pointer;
  }
  
  .spotify-album-grid .custom-album-check .form-check {
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
  }
  
  .custom-album-check input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    font: inherit;
    color: currentColor;
  
    border: 1px solid currentColor;
    border-radius: 4px;
    transform: translateY(0.075em);
    display: grid;
    place-content: center;
    border-color: $gray-300;
    position: absolute;
    inset: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    background: transparent;
  }
  .custom-album-check input[type='checkbox']:checked {
    border-color: $primary;
    background: transparent;
  }
  .custom-album-check label {
    display: hidden;
  }
  
  .custom-album-check input[type='checkbox']:checked::after {
    opacity: 1;
  }
  
  .custom-album-check input[type='checkbox']::before {
    content: '';
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    top: -8px;
    right: 3px;
    border-radius: 1rem;
    background: $primary;
    opacity: 0;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 -50 512 512'%3E %3Cpath fill='%23FFF' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E %3C/svg%3E");
  }
  .custom-album-check input[type='checkbox']:checked::before {
    opacity: 1;
  }