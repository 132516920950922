/**********************************************/
/* DROPDOWNS */
/**********************************************/
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
$dropdown-min-width: 10rem;
$dropdown-padding-x: 0;
$dropdown-padding-y: 0.25rem;
$dropdown-spacer: 0.125rem;
$dropdown-font-size: $font-size-base;
$dropdown-color: $body-color;
$dropdown-bg: $white;
$dropdown-border-color: shade-color($light, 3%);
$dropdown-border-radius: $border-radius;
$dropdown-border-width: $border-width;
$dropdown-inner-border-radius: subtract($dropdown-border-radius, $dropdown-border-width);
$dropdown-divider-bg: $dropdown-border-color;
$dropdown-divider-margin-y: calc($spacer / 3);
$dropdown-box-shadow: $box-shadow;

$dropdown-link-color: $body-color;
$dropdown-link-hover-color: shade-color($gray-900, 10%);
$dropdown-link-hover-bg: tint-color($gray-100, 22%);

$dropdown-link-active-color: $dark;
$dropdown-link-active-bg: tint-color($light, 24%);

$dropdown-link-disabled-color: $gray-600;

$dropdown-item-padding-y: 0.375rem;
$dropdown-item-padding-x: calc($spacer / 2);

$dropdown-header-color: inherit;
$dropdown-header-padding: 0.5rem 1.5rem;
// scss-docs-end dropdown-variables

// scss-docs-start dropdown-dark-variables
$dropdown-dark-color: $gray-300;
$dropdown-dark-bg: $gray-800;
$dropdown-dark-border-color: $dropdown-border-color;
$dropdown-dark-divider-bg: $dropdown-divider-bg;
$dropdown-dark-box-shadow: null;
$dropdown-dark-link-color: $dropdown-dark-color;
$dropdown-dark-link-hover-color: $white;
$dropdown-dark-link-hover-bg: rgba($white, 0.15);
$dropdown-dark-link-active-color: $dropdown-link-active-color;
$dropdown-dark-link-active-bg: $dropdown-link-active-bg;
$dropdown-dark-link-disabled-color: $gray-500;
$dropdown-dark-header-color: $gray-500;
// scss-docs-end dropdown-dark-variables
