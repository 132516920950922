//
// dashboard.scss
//

@media (min-width: 1900px) {
  .dash-left:has(+ .dash-right) {
    padding-right: 1.5rem !important; padding-top: 1.5rem !important;margin-top: -1.5rem !important; border-right: 1px solid $gray-300;
  }
  .dash-right {
    padding-left: 1.5rem !important; padding-top: 1.5rem !important;margin-top: -1.5rem !important; 
  }
}