///////////////////////////////////////////////////
// AUDIO UPLOAD TOOL ( AUT )
///////////////////////////////////////////////////
/* Layout Change */
.content-page:has(.content):has(.container-fluid):has(.aut-layout) {
  padding: $topbar-height 0 0 !important;
  .content {
    .container-fluid:has(.aut-layout) {
      max-width: 100% !important;
      padding: 0;
      z-index: 1;
      position: relative;
    }
  }
  @include media-breakpoint-down(xxxl) {
    padding: $topbar-height 0 0 0 !important;
  }
}
.content-page:has(.content):has(.container-fluid):has(.aut-layout) .footer {
  display: none !important;
}

/* Layout */
.aut-layout {
  height: calc(100vh - $topbar-height);
  .aut-main {
    flex-grow: 1;
    overflow: scroll;
    .aut-main-content {
      max-width: 1280px;
      margin: 0 auto;
    }
  }
  .aut-side {
    position: absolute;
    bottom: 0;
    .aut-side-content {
    }
  }

  @include media-breakpoint-up(xxxl) {
    padding-right: 500px;
    .aut-side {
      position: absolute;
      min-width: 500px;
      max-width: 500px;
      top: 0;
      right: 0;
    }
  }
  @include media-breakpoint-down(xxxl) {
    .aut-main {
      padding-bottom: 1rem;
    }
  }
  @include media-breakpoint-down(xl) {
    .aut-main {
      padding-bottom: 4rem;
    }
  }
  @include media-breakpoint-down(md) {
    .aut-main {
      padding-bottom: 9rem;
    }
  }

  .form-control,
  .form-select {
    box-shadow: $box-shadow-xs;
    &:focus {
      border-color: $primary;
    }
  }
}

/* AUT Accordions */
.aut-accordion {
  box-shadow: $box-shadow-sm;
  border: 1px solid $gray-300;
  transition: all 0.15s ease-in-out;
  &:hover {
    border-color: $primary;
  }
  .aut-accordion-header {
    background-color: white;
    min-height: 96px;
    .drag-icon {
      fill: $gray-500;
    }
    .btn-delete {
      color: $gray-300;
      &:hover {
        color: $danger;
      }
    }
    .btn-action {
      color: $gray-300;
      &:hover {
        color: $primary;
      }
    }
    .title {
      color: $gray-700;
    }
    &:hover {
      .drag-icon {
        fill: $gray-700;
        &:hover {
          cursor: grab;
        }
      }
      .title {
        color: $primary-600;
      }
    }
  }
  .aut-accordion-body {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    box-shadow: 0 -1px 0 $gray-300;
  }
  &.aut-accordion-open {
    box-shadow: $box-shadow-sm;
    border-color: $gray-300;
    .aut-accordion-header {
      .title {
        color: $primary-600;
      }
    }
    .aut-accordion-body {
      max-height: 500px;
    }
  }
}

.aut-accordion-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.aut-accordion-body.open {
  max-height: 500px; /* Adjust based on expected content height */
}
