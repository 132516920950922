/**********************************************/
/* TABLES */
/**********************************************/

// scss-docs-end table-variables
$table-cell-padding-y:        1rem;
// scss-docs-end table-variables

// scss-docs-start table-loop
$table-variants: (
  'primary': shift-color($primary, $table-bg-scale),
  'secondary': shift-color($secondary, $table-bg-scale),
  'success': shift-color($success, $table-bg-scale),
  'info': shift-color($info, $table-bg-scale),
  'warning': shift-color($warning, $table-bg-scale),
  'danger': shift-color($danger, $table-bg-scale),
  'light': $white,
  'dark': $secondary-900,
);
// scss-docs-end table-loop


