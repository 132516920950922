/**********************************************/
/* BODY */
/**********************************************/
$body-bg: $gray-100;
$body-color:$gray-900;
$body-text-align: null;

/**********************************************/
/* LOGIN */
/**********************************************/
$login-bg: $secondary-900; 
$login-card-bg: hsla(0,0%,100%,.1);
.login-card-bg { background: $login-card-bg!important; } 

/**********************************************/
/* TOPBAR */
/**********************************************/
$topbar-height:                     88px;

// Topbar Background
$bg-topbar:                       $secondary-900;
$bg-topbar-dark:                  $secondary-900;

// Topbar Search

/* Change of overall content paddings: Brought from /custom/structure/left-menu.scss */
.content-page {
    padding: $topbar-height 24px 65px!important;
}
/* TOPBAR curved top left corner */
@media (min-width: 992px) {
    .navbar-custom { 
        position: relative;
        &::before {
            content:""; position: absolute; top:0; left: 0; width: 100%; height:8px; background: $secondary-900;
        }
        &::after { 
            content: ""; position: absolute; top:8px; left: 0; width: 48px; height: 48px; background-position: top left; background-repeat: no-repeat;  background-size: contain;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgNDBDMCAzOS44NjcxIDAuMDAwMTAwNjg1IDM5LjczMSAwLjAwMDEwMDY4NSAzOS41OTA4QzAuMDAwMTAwNjg1IDE3LjcyNjEgMTcuNzI1NSAwIDM5LjU5MDkgMEgwVjQwWiIgZmlsbD0iIzAwMDE1NyIvPgo8L3N2Zz4K");
        }
    }
}

/**********************************************/
/* LEFTBAR */
/**********************************************/
// Background left-sidebar
$bg-leftbar:                        $secondary-900;
$bg-leftbar-light:                  $secondary-900;
$bg-leftbar-dark:                   linear-gradient(to left, #000157, #001162);
$bg-topnav:                         $white;

// Deatched left sidenav
$bg-detached-leftbar:               $white;

// Logo Display
$logo-light-display:                block;
$logo-dark-display:                 none;

// Logo auth display
$logo-auth-light-display:           none;
$logo-auth-dark-display:            block;

// Leftbar width
$leftbar-width:                     260px;

// Boxed Layout
$boxed-layout-width:                1300px;
$boxed-layout-bg:                   $white;

// Menu item colors (Default-dark)
$menu-item:                         $secondary-200;
$menu-item-hover:                   $secondary-100;
$menu-item-active:                  $white;

// Menu item colors (light)
$menu-item-light:                   $secondary-200;
$menu-item-light-hover:             $secondary-100;
$menu-item-light-active:            $white;

// Detached Menu item colors
$menu-item-dark:                    $gray-200;
$menu-item-dark-hover:              $gray-300;
$menu-item-dark-active:             $white;

// Dark sidenav Menu item color
$sidebar-dark-menu-item:            $secondary-200;
$sidebar-dark-menu-item-hover:      $secondary-100;
$sidebar-dark-menu-item-active:     $white;

// Rightbar Width
$rightbar-width:                    280px;
$rightbar-bg:                       $white;
$rightbar-title-bg:                 $dark;
$rightbar-title-color:              $white;
$rightbar-title-btn-bg:             $primary;
$rightbar-title-btn-color:          $white;
$rightbar-overlay-bg:               $gray-700;



